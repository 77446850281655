import React, { useEffect, useState } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout/Layout';
import DownloadSEO from '../../components/Seo/DownloadSEO';
import DownloadInstallationsStep from '../../sections/DownloadInstallationsStep/DownloadInstallationsStep';
import DownloadMain from '../../sections/DownloadMain/DownloadMain';

import * as css from './download.module.scss';

const MAC_PLATFORMES = ['Mac68K', 'MacPPC', 'MacIntel'];

const DownloadPage = ({ data }: { data: any }) => {
  const { imageUrl, productImage } = data;
  const imageOg = imageUrl.edges[0]?.node.fluid.src;
  const productImageSrc = productImage.edges[0]?.node.fluid.src;

  const [os, setOs] = useState<'windows' | 'macos'>('windows');
  const queryClient = new QueryClient();

  useEffect(() => {
    if (MAC_PLATFORMES.includes(navigator.platform)) {
      setOs('macos');
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <DownloadSEO imageUrl={imageOg} productImage={productImageSrc} />
        <div className="container-flex">
          <div className="container" style={{ marginTop: '50px' }}>
            <DownloadMain os={os} />
          </div>
        </div>
      </Layout>
    </QueryClientProvider>
  );
};

export default DownloadPage;

export const query = graphql`
  query {
    imageUrl: allImageSharp(
      filter: { fluid: { originalName: { eq: "install_mac.png" } } }
    ) {
      edges {
        node {
          id
          fluid {
            src
          }
        }
      }
    }
    productImage: allImageSharp(
      filter: { fluid: { originalName: { eq: "App1.png" } } }
    ) {
      edges {
        node {
          id
          fluid {
            src
          }
        }
      }
    }
  }
`;
