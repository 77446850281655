import React from 'react';

import { Helmet } from 'react-helmet';

export type JsonLd = {
  productImageUrl: string;
};

const JsonLd = ({ productImageUrl }) => {
  const jsonLd = [
    {
      '@context': 'https://schema.org',
      '@type': ['SoftwareApplication'],
      name: 'AutoCut',
      operatingSystem: 'Windows, OSX',
      applicationCategory: 'MultimediaApplication',
      offers: {
        '@type': 'AggregateOffer',
        offerCount: '4',
        lowPrice: '0',
        highPrice: '199',
        priceCurrency: 'EUR',
      },
      review: {
        '@type': 'Review',
        name: 'AutoCut is now an integral part of my workflow, I can no longer edit without it. AutoCut allows me to de-rush much faster and make my videos more dynamic !',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: '5',
          bestRating: '5',
        },
        author: {
          '@type': 'Person',
          name: 'Vincent Péchon',
        },
      },
    },
    {
      '@context': 'https://schema.org/',
      '@type': ['SubscriptionService', 'SoftwareApplication'],
      name: 'AutoCut - Monthly',
      image: ['productImageUrl'],
      description:
        'AutoCut is a Premiere Pro extension (plugin) that automatically removes silences from video and make your jumps cuts automatically !',
      offers: {
        '@type': 'Offer',
        price: '9.80',
        priceCurrency: 'EUR',
      },
    },
    {
      '@context': 'https://schema.org/',
      '@type': ['SubscriptionService', 'SoftwareApplication'],
      name: 'AutoCut - Yearly',
      image: [productImageUrl],
      description:
        'AutoCut is a Premiere Pro extension (plugin) that automatically removes silences from video and make your jumps cuts automatically !',
      offers: {
        '@type': 'Offer',
        price: '94.80',
        priceCurrency: 'EUR',
      },
    },
    {
      '@context': 'https://schema.org/',
      '@type': ['Product', 'SoftwareApplication'],
      name: 'AutoCut - Life time',
      image: [productImageUrl],
      description:
        'AutoCut is a Premiere Pro extension (plugin) that automatically removes silences from video and make your jumps cuts automatically !',
      offers: {
        '@type': 'Offer',
        price: '199',
        priceCurrency: 'EUR',
      },
    },
  ];

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
    </Helmet>
  );
};

export default JsonLd;
