import React from 'react';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { useForm } from 'react-hook-form';

import Button from '../../components/Button/Button';
import MainTitle from '../../components/Title/MainTitle/MainTitle';
import { useDimension } from '../../hooks/useDimension';

import * as css from './DownloadMain.module.scss';

export type DownloadMainProps = {
  os: 'windows' | 'macos';
};

const DownloadMain = ({ os }: DownloadMainProps) => {
  const mutate = useMutation({
    mutationFn: newData => {
      console.log('newData', newData);
      return axios.post(
        'https://hooks.slack.com/services/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',
        JSON.stringify(newData),
        {
          withCredentials: false,
        }
      );
    },
  });

  const submit = (data: any) => {
    console.log(data);
    const text = {
      text: `Name : *${data.firstName} ${data.name}*\nEmail : ${data.email})\nCompany: *${data.company}* \nJob: *${data.job}*, \nCity: *${data.city}*`,
    };
    mutate.mutate(text as any);
  };

  const { register, handleSubmit } = useForm();

  const dimensions = useDimension();

  //Use useForm
  return (
    <div className={css.container}>
      <MainTitle
        titleId="download.mainTitle.title"
        subtitleId="download.mainTitle.subtitle"
      />
      <div className={css.formContainer}>
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          className={css.form}
          onSubmit={handleSubmit(submit)}
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className={css.formRow}>
            <div className={css.formGroup}>
              <label htmlFor="firstName">
                <FormattedMessage id="download.form.firstName" />
              </label>
              <input
                {...register('firstName')}
                type="text"
                name="firstName"
                id="firstName"
              />
            </div>
            <div className={css.formGroup}>
              <label htmlFor="name">
                <FormattedMessage id="download.form.name" />
              </label>
              <input {...register('name')} type="text" name="name" id="name" />
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formGroup}>
              <label htmlFor="email">
                <FormattedMessage id="download.form.email" />
              </label>
              <input
                {...register('email')}
                type="email"
                name="email"
                id="email"
              />
            </div>
            <div className={css.formGroup}>
              <label htmlFor="company">
                <FormattedMessage id="download.form.company" />
              </label>
              <input
                {...register('company')}
                type="text"
                name="company"
                id="company"
              />
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formGroup}>
              <label htmlFor="job">
                <FormattedMessage id="download.form.job" />
              </label>
              <input {...register('job')} type="text" name="job" id="job" />
            </div>

            <div className={css.formGroup}>
              <label htmlFor="city">
                <FormattedMessage id="download.form.city" />
              </label>
              <input {...register('city')} type="text" name="city" id="city" />
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formGroup}>
              <Button
                type="submit"
                titleId={
                  os == 'macos'
                    ? 'download.downloadButtons.macos.title'
                    : 'download.downloadButtons.windows.title'
                }
                variant="primary"
              />
            </div>
          </div>
        </form>
      </div>
      <div className={css.buttonContainer}></div>
    </div>
  );
};

export default DownloadMain;
