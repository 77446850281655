import React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import { Helmet } from 'react-helmet';

import JsonLd from './JsonLd';

export type DownloadSEOProps = {
  imageUrl: string;
  productImage: string;
};

const DownloadSEO = ({ imageUrl, productImage }: DownloadSEOProps) => {
  const intl = useIntl();
  return (
    <>
      <Helmet
        defaultTitle={intl.formatMessage({
          id: 'download-seo.title',
        })}
        lang={intl.formatMessage({
          id: 'seo.lang',
        })}
        description={intl.formatMessage({
          id: 'download-seo.description',
        })}
        meta={[
          {
            name: `description`,
            content: intl.formatMessage({
              id: 'download-seo.description',
            }),
          },
          {
            property: `og:title`,
            content: intl.formatMessage({
              id: 'download-seo.title',
            }),
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: `og:url`,
            content: `https://www.autocut.fr/${intl.locale}/download`,
          },
          {
            property: `og:image`,
            content: `https://www.autocut.fr${imageUrl}`,
          },
          {
            property: `og:description`,
            content: intl.formatMessage({
              id: 'download-seo.description',
            }),
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: `twitter:title`,
            content: intl.formatMessage({
              id: 'download-seo.title',
            }),
          },
          {
            name: `twitter:image`,
            content: `https://www.autocut.fr${imageUrl}`,
          },
          {
            name: `twitter:description`,
            content: intl.formatMessage({
              id: 'download-seo.description',
            }),
          },
          {
            name: 'twitter:site',
            content: '@ToolPad_fr',
          },
          {
            name: 'twitter:creator',
            content: '@ToolPad_fr',
          },
        ]}
      />
      <JsonLd productImageUrl={productImage} />
    </>
  );
};

export default DownloadSEO;
